import {
  SupportedUILanguagesApi,
  GetAllSupportedUILanguagesResponse,
} from '@uniqkey-backend-partner/api-client';
import { AxiosRequestConfig } from 'axios';
import { publicAxiosInstance } from '../../axios';
import config from '../../config';
import { dataExtractor } from '../../helpers/apiClients';

const API_URL = config.getPartnerApiUrl();

const getSupportedLanguagesAPIClient = () => new SupportedUILanguagesApi(
  undefined,
  API_URL,
  publicAxiosInstance,
);

export const fetchSupportedLanguages = async (
  params: AxiosRequestConfig = {},
) => getSupportedLanguagesAPIClient()
  .apiV1SupportedUILanguagesGetAllGet(params)
  .then(dataExtractor<GetAllSupportedUILanguagesResponse>());
