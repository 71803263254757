import { memo } from 'react';
import { isNumber } from 'lodash';
import { useTranslations, SecurityScoreIcon, Tooltip } from '@uniqkey-frontend/shared-app';
import { Password2FaStatus } from '@uniqkey-backend-partner/api-client';
import SecurityScoreStatisticsTooltipContent from '../tables/SecurityScoreStatisticsTooltipContent';

interface IColumnWithSecurityScoreProps {
  securityScore?: number | null;
  strength?: number | null;
  password2FaStatus: Password2FaStatus;
  hasReusedPassword: boolean;
}

const ColumnWithSecurityScore = (params: IColumnWithSecurityScoreProps) => {
  const {
    securityScore, strength, password2FaStatus, hasReusedPassword,
  } = params;
  const { t } = useTranslations();

  if (!isNumber(securityScore)) {
    return t('common.noScore');
  }

  return (
    <Tooltip
      cursorPointer
      title={(
        <SecurityScoreStatisticsTooltipContent
          strength={strength ?? 0}
          password2FaStatus={password2FaStatus}
          hasReusedPassword={hasReusedPassword}
        />
      )}
    >
      <SecurityScoreIcon percentage={Math.floor(securityScore)} />
    </Tooltip>
  );
};

export default memo(ColumnWithSecurityScore);
