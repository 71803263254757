import {
  WidgetContainer,
  Grid,
  Typography,
  Button,
  useTranslations,
  S5,
  S6,
  useSnackbar,
  resetReactQuery,
  Tooltip,
} from '@uniqkey-frontend/shared-app';
import {
  useCallback, useState, memo, useEffect, useMemo,
} from 'react';
import {
  PartnerDeletedNotification,
  GetPartnerByIdResponse,
} from '@uniqkey-backend-partner/api-client';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useGetPartnerById } from '../../../../../../hooks/reactQuery';
import DeletePartnerModal, { IDeletePartnerModalSubmitResult } from '../DeletePartnerModal';
import usePartnersAPI from '../../../../../../hooks/usePartnersAPI';
import { useUser } from '../../../../../../contexts/UserContext';
import ACLEnum from '../../../../../../enums/ACLEnum';
import { subscribeToRealtimeAPIEvent } from '../../../../../../services/webSocketsManager';
import RealtimeAPIEventTypeEnum from '../../../../../../enums/RealtimeAPIEventTypeEnum';
import { logException } from '../../../../../../services/sentryService';
import PageRouteEnum from '../../../../../../enums/PageRouteEnum';
import { isUniqkey } from '../../../../../../helpers/partnerType';
import { REACT_QUERY_PARTNERS_KEY } from '../../../../../../hooks/tables/usePartnersTable';

interface IDeletePartnerWidgetProps {
  partnerId: string;
}

const SUCH_DELETION_REQUEST_CANNOT_BE_FOUND_ERROR = 'Such_DeletionRequest_cannot_be_found';

const DeletePartnerWidget = (props: IDeletePartnerWidgetProps) => {
  const { partnerId } = props;
  const { t } = useTranslations();
  const { showError, showSuccess } = useSnackbar();
  const navigate = useNavigate();
  const { userCan, currentUser } = useUser();
  const queryClient = useQueryClient();

  const { data: partner, isLoading: isPartnerLoading } = useGetPartnerById({ partnerId }, {
    onError: () => {
      showError({ text: t('common.somethingWentWrong') });
    },
  });
  const { requestPartnerDeletion, deletePartner } = usePartnersAPI();

  const [isDeletePartnerModalOpen, setIsDeletePartnerModalOpen] = useState(false);
  const [isDeletePartnerModalOpenLoading, setIsDeletePartnerModalOpenLoading] = useState(false);
  const [isDeletePartnerLoading, setIsDeletePartnerLoading] = useState(false);

  const handleDeletePartnerModalOpen = useCallback(async () => {
    try {
      setIsDeletePartnerModalOpenLoading(true);
      await requestPartnerDeletion({ partnerId });
      setIsDeletePartnerModalOpen(true);
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, { message: 'DeletePartnerWidget/handleDeletePartnerModalOpen exception' });
    } finally {
      setIsDeletePartnerModalOpenLoading(false);
    }
  }, [partnerId, requestPartnerDeletion, showError, t]);

  const handleDeletePartnerModalClose = useCallback(
    () => setIsDeletePartnerModalOpen(false),
    [],
  );

  const handleDeletePartner = useCallback(async (params: IDeletePartnerModalSubmitResult) => {
    const { code } = params;
    try {
      setIsDeletePartnerLoading(true);
      await deletePartner(code, partnerId);
      resetReactQuery(queryClient, REACT_QUERY_PARTNERS_KEY);
    } catch (e: any) {
      let key = 'common.somethingWentWrong';
      if (e?.response?.data?.includes(SUCH_DELETION_REQUEST_CANNOT_BE_FOUND_ERROR)) {
        key = 'deletePartnerWidget.suchDeletionRequestCannotBeFound';
      }
      showError({ text: t(key) });
      logException(e, { message: 'DeletePartnerWidget/handleDeletePartner exception' });
      setIsDeletePartnerLoading(false);
    }
  }, [deletePartner, partnerId, queryClient, showError, t]);

  useEffect(() => {
    const unsubscribe = subscribeToRealtimeAPIEvent<PartnerDeletedNotification>(
      RealtimeAPIEventTypeEnum.PartnerDeletedNotification,
      async (event) => {
        const { isSuccessful } = event;
        if (!isSuccessful) {
          setIsDeletePartnerLoading(false);
          showError({ text: t('common.somethingWentWrong') });
          return;
        }
        navigate(PageRouteEnum.Partners);
        showSuccess({ text: t('deletePartnerWidget.successMessage') });
      },
    );
    return () => {
      unsubscribe();
    };
  }, [navigate, showError, showSuccess, t]);

  const { partnerId: currentUserPartnerId, partnerType } = currentUser ?? {};
  const { name, parentId } = partner ?? {} as GetPartnerByIdResponse;

  const { canDeletePartner, deletePartnerButtonTooltip } = useMemo(() => {
    if (!userCan(ACLEnum.PartnerDelete)) {
      return {
        canDeletePartner: false,
        deletePartnerButtonTooltip: t('deletePartnerWidget.tooltip.onlyAdmin'),
      };
    }
    if (isUniqkey(partnerType)) { // Uniqkey partner can delete partners
      return {
        canDeletePartner: true,
        deletePartnerButtonTooltip: null,
      };
    }
    if (currentUserPartnerId === parentId) {
      // can delete partners if the partner for deletion is a direct child of the current partner
      return {
        canDeletePartner: true,
        deletePartnerButtonTooltip: null,
      };
    }
    return {
      canDeletePartner: false,
      deletePartnerButtonTooltip: t('deletePartnerWidget.tooltip.onlyParent'),
    };
  }, [userCan, partnerType, currentUserPartnerId, parentId, t]);

  if (!partner) {
    return null;
  }

  return (
    <WidgetContainer
      container
      flexDirection="column"
      py={3}
      withShadow
      isLoading={isPartnerLoading}
      rowGap={4}
      flexWrap="nowrap"
    >
      <Grid container flexDirection="column" rowGap={2}>
        <Typography variant="subtitle1" color={S6}>
          {t('deletePartnerWidget.title')}
        </Typography>
        <Typography variant="caption" color={S5}>
          {t('deletePartnerWidget.description')}
        </Typography>
      </Grid>
      <Tooltip title={deletePartnerButtonTooltip} followCursor>
        <Grid container item xs={6}>
          <Button
            color="error"
            fullWidth
            isLoading={isDeletePartnerModalOpenLoading}
            disabled={!canDeletePartner}
            onClick={handleDeletePartnerModalOpen}
          >
            {t('deletePartnerWidget.deletePartner')}
          </Button>
        </Grid>
      </Tooltip>
      {isDeletePartnerModalOpen && (
        <DeletePartnerModal
          partnerName={name}
          partnerId={partnerId}
          isOpen={isDeletePartnerModalOpen}
          isDeletePartnerLoading={isDeletePartnerLoading}
          onSubmit={handleDeletePartner}
          onClose={handleDeletePartnerModalClose}
        />
      )}
    </WidgetContainer>
  );
};

export default memo(DeletePartnerWidget);
