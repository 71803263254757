import { useCallback } from 'react';
import { GetAccessRightsResponse, GetRolesResponse } from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';
import { TGetACLsByRoleMethod, TGetRolesMethod } from './interfaces';

const usePermissionsAPI = () => {
  const { permissionsAPIClient } = useApiClients();

  const getRoles = useCallback<TGetRolesMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
      } = params;
      return permissionsAPIClient.apiV1PermissionsGetRolesGet(
        page,
        pageLength,
        axiosRequestConfig,
      ).then(dataExtractor<GetRolesResponse>());
    },
    [permissionsAPIClient],
  );

  const getACLsByRole = useCallback<TGetACLsByRoleMethod>(
    (params, axiosRequestConfig) => {
      const {
        page,
        pageLength,
        role,
      } = params;
      return permissionsAPIClient.apiV1PermissionsGetAccessRightsGet(
        page,
        pageLength,
        role,
        axiosRequestConfig,
      ).then(dataExtractor<GetAccessRightsResponse>());
    },
    [permissionsAPIClient],
  );

  return {
    getRoles,
    getACLsByRole,
  };
};

export default usePermissionsAPI;
