import { TUseQueryOptions } from '@uniqkey-frontend/shared-app';
import { GetMoveInfoResponse } from '@uniqkey-backend-partner/api-client';
import { useQuery, type QueryKey } from 'react-query';
import usePartnerSettingsAPI from '../../usePartnerSettingsAPI';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';

const REACT_QUERY_PARTNER_MOVE_INFO_KEY = [ReactQueryKeyEnum.PartnerMoveInfo];

interface IUseGetPartnerMoveInfoParams {
  partnerId: string;
}

// TODO: Remove after more exports will be added
// eslint-disable-next-line import/prefer-default-export
export const useGetPartnerMoveInfo = (
  params: IUseGetPartnerMoveInfoParams,
  options: TUseQueryOptions<GetMoveInfoResponse> = {},
) => {
  const { partnerId } = params;
  const { getPartnerMoveInfo } = usePartnerSettingsAPI();
  return useQuery<GetMoveInfoResponse>(
    (REACT_QUERY_PARTNER_MOVE_INFO_KEY as QueryKey[]).concat([partnerId]),
    ({ signal }) => getPartnerMoveInfo(partnerId, { signal }),
    { notifyOnChangeProps: 'tracked', ...options },
  );
};
