import {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import {
  Grid,
  Panel,
  Box,
  Button,
  OrganizationIcon,
  FilterButton,
  useTranslations,
  useSnackbar,
  usePopper,
  SearchableTextField,
  Tooltip,
  ISearchableTextFieldProps,
  IFilterButtonProps,
  RenderIf,
  ExportButton,
  Divider,
  ActionButton,
  MoveIcon,
  useMapKeyValueExtractor,
} from '@uniqkey-frontend/shared-app';
import {
  CreatePartnerOrganizationRequest,
  MeResponse,
  OrganizationsMovedNotification,
} from '@uniqkey-backend-partner/api-client';
import usePartnerOrganizationsAPI from '../../hooks/usePartnerOrganizationsAPI';
import useOrganizationsExtendedTable, {
  IOrganizationsExtendedTableRow,
} from '../../hooks/tables/useOrganizationsExtendedTable';
import { useLanguages } from '../../hooks/reactQuery';
import OrganizationsExtendedTable from '../../components/tables/OrganizationsExtendedTable';
import CreateOrganizationModal from '../OrganizationsPage/components/CreateOrganizationModal';
import OrganizationsExtendedListFilter, {
  IOrganizationsExtendedListFilterSubmitResult,
} from './components/OrganizationsExtendedListFilter';
import { logException } from '../../services/sentryService';
import DownloadCSVModal, { IDownloadCSVModalSubmitResult } from '../../components/DownloadCSVModal';
import useSubscribeToExportLicenseDataResultNotificationEvent
  from '../../hooks/useSubscribeToExportLicenseDataResultNotificationEvent';
import ACLEnum from '../../enums/ACLEnum';
import { useUser } from '../../contexts/UserContext';
import OrganizationsExtendedTableWithFilter
  from '../../components/tables/OrganizationsExtendedTableWithFilter';
import { isUniqkey } from '../../helpers/partnerType';
import MoveOrganizationsModal, {
  IMoveOrganizationsModalSubmitResult,
} from './components/MoveOrganizationsModal';
import { subscribeToRealtimeAPIEvent } from '../../services/webSocketsManager';
import RealtimeAPIEventTypeEnum from '../../enums/RealtimeAPIEventTypeEnum';

const FIRST_ADMIN_NOT_ACTIVATED_ERROR = 'First_admin_not_activated';

const OrganizationsExtendedPage = () => {
  const { currentUser, userCan } = useUser();
  const { partnerType, partnerId } = currentUser ?? {} as MeResponse;
  const [isCreateOrganizationModalOpen, setIsCreateOrganizationModalOpen] = useState(false);
  const handleCreateOrganizationModalOpen = useCallback(
    () => setIsCreateOrganizationModalOpen(true),
    [],
  );
  const handleCreateOrganizationModalClose = useCallback(
    () => setIsCreateOrganizationModalOpen(false),
    [],
  );
  const [isCreateOrganizationLoading, setIsCreateOrganizationLoading] = useState(false);
  const [isDownloadCSVModalOpen, setIsDownloadCSVModalOpen] = useState(false);
  const [isDownloadCSVLoading, setIsDownloadCSVLoading] = useState(false);
  const [isMoveOrganizationsModalOpen, setIsMoveOrganizationsModalOpen] = useState(false);
  const [isMoveOrganizationsLoading, setIsMoveOrganizationsLoading] = useState(false);
  const handleDownloadCSVModalOpen = useCallback(() => setIsDownloadCSVModalOpen(true), []);
  const handleDownloadCSVModalClose = useCallback(() => setIsDownloadCSVModalOpen(false), []);
  const handleExportLicenseDataSuccess = useCallback(() => {
    handleDownloadCSVModalClose();
    setIsDownloadCSVLoading(false);
  }, [handleDownloadCSVModalClose]);
  const handleExportLicenseDataError = useCallback(() => {
    setIsDownloadCSVLoading(false);
  }, []);
  const handleMoveOrganizationsModalOpen = useCallback(
    () => setIsMoveOrganizationsModalOpen(true),
    [],
  );
  const handleMoveOrganizationsModalClose = useCallback(
    () => setIsMoveOrganizationsModalOpen(false),
    [],
  );

  // preload for create organization modal
  useLanguages({ includeOnlyAllowedLanguages: false });

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );

  const handleFilterClose = useCallback(
    () => setIsFilterOpen(false),
    [setIsFilterOpen],
  );

  const { t } = useTranslations();
  const {
    createOrganization,
    requestOrganizationLicenseDataExport,
    moveOrganizations,
  } = usePartnerOrganizationsAPI();
  const { showSuccess, showError } = useSnackbar();

  const {
    selectedOrganizationsExtended,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetQuery,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = useOrganizationsExtendedTable({
    noDataMessageKey: 'organizationsPage.noData',
  });

  const {
    values: selectedOrganizationsExtendedAsObjects, keys: selectedOrganizationsExtendedIds,
  } = useMapKeyValueExtractor<IOrganizationsExtendedTableRow>(selectedOrganizationsExtended);

  const {
    isMoveOrganizationsDisabled,
    moveTooltipTitle,
  } = useMemo(() => {
    if (!selectedOrganizationsExtendedAsObjects.length) {
      return {
        isMoveOrganizationsDisabled: true,
        moveTooltipTitle: t('moveOrganizationsModal.defaultTooltip'),
      };
    }
    const uniqkeyId = isUniqkey(partnerType) ? partnerId : null;
    let disableMovePartners = false;
    let moveTitle = t('moveOrganizationsModal.defaultTooltip');
    if (selectedOrganizationsExtendedAsObjects.some(
      (organization) => organization.partnerId !== uniqkeyId,
    )) {
      disableMovePartners = true;
      moveTitle = t('moveOrganizationsModal.disabledTooltip');
    }
    return {
      isMoveOrganizationsDisabled: disableMovePartners,
      moveTooltipTitle: moveTitle,
    };
  }, [partnerId, partnerType, selectedOrganizationsExtendedAsObjects, t]);

  const canExportOrganizationsList = userCan(ACLEnum.OrganizationExportList);

  const TABLE_OPTIONS = useMemo(() => ({
    selection: canExportOrganizationsList,
  }), [canExportOrganizationsList]);

  const handleCreateOrganization = useCallback(async (
    organization: CreatePartnerOrganizationRequest,
  ) => {
    try {
      setIsCreateOrganizationLoading(true);
      await createOrganization(organization);
      showSuccess({
        text: t('createOrganizationModal.organizationCreated'),
      });
      handleCreateOrganizationModalClose();
      resetQuery();
    } catch (e: any) {
      let key = 'common.somethingWentWrong';
      if (e?.response?.data?.includes(FIRST_ADMIN_NOT_ACTIVATED_ERROR)) {
        key = 'createOrganizationModal.firstAdminNotActivatedError';
      }
      showError({ text: t(key) });
      logException(e, {
        message: 'OrganizationsExtendedPage/handleCreateOrganization exception',
      });
    } finally {
      setIsCreateOrganizationLoading(false);
    }
  }, [
    createOrganization,
    handleCreateOrganizationModalClose,
    resetQuery,
    showError,
    showSuccess,
    t,
  ]);

  const handleDownloadCSV = useCallback(async (value: IDownloadCSVModalSubmitResult) => {
    try {
      setIsDownloadCSVLoading(true);
      await requestOrganizationLicenseDataExport(value);
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'OrganizationsExtendedPage/handleDownloadCSV exception',
      });
      setIsDownloadCSVLoading(false);
    }
  }, [requestOrganizationLicenseDataExport, showError, t]);

  useSubscribeToExportLicenseDataResultNotificationEvent({
    from: 'OrganizationsExtendedPage',
    onSuccess: handleExportLicenseDataSuccess,
    onError: handleExportLicenseDataError,
  });

  const handleMoveOrganizations = useCallback(async (
    value: IMoveOrganizationsModalSubmitResult,
  ) => {
    try {
      const { targetPartnerId } = value;
      setIsMoveOrganizationsLoading(true);
      await moveOrganizations({
        sourcePartnerId: partnerId,
        organizationIds: selectedOrganizationsExtendedIds,
        targetPartnerId,
      });
      resetQuery();
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
      logException(e, {
        message: 'OrganizationsExtendedPage/handleMoveOrganizations exception',
      });
      setIsMoveOrganizationsLoading(false);
    }
  }, [moveOrganizations, partnerId, resetQuery, selectedOrganizationsExtendedIds, showError, t]);

  useEffect(() => {
    const unsubscribe = subscribeToRealtimeAPIEvent<OrganizationsMovedNotification>(
      RealtimeAPIEventTypeEnum.OrganizationsMovedNotification,
      (event) => {
        const { moveResult } = event;
        const { successCount, failedCount } = moveResult.reduce(
          (acc, result) => {
            if (result.isSuccessful) {
              acc.successCount += 1;
            } else {
              acc.failedCount += 1;
            }
            return acc;
          },
          { successCount: 0, failedCount: 0 },
        );
        if (successCount) {
          showSuccess({
            text: t('moveOrganizationsModal.successMessage', { count: successCount }),
          });
        }
        if (failedCount) {
          showError({ text: t('moveOrganizationsModal.errorMessage', { count: failedCount }) });
        }
        setIsMoveOrganizationsLoading(false);
        handleMoveOrganizationsModalClose();
        resetSelectedRows();
      },
    );
    return () => {
      unsubscribe();
    };
  }, [handleMoveOrganizationsModalClose, resetSelectedRows, showError, showSuccess, t]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((
    updatedValues: IOrganizationsExtendedListFilterSubmitResult,
  ) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const { movedFromPartner, allMovedPartners } = filterValues ?? {};

  return (
    <Grid container flexDirection="column" className="min-height-100-percent">
      <Grid item mb={1}>
        <Panel>
          <Box p={1}>
            <Grid container justifyContent="space-between" alignItems="stretch">
              <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
                <Grid item>
                  <Tooltip title={t('common.filter')}>
                    <FilterButton
                      isFilterActive={isFilterActive}
                      numberOfActiveFilters={numberOfActiveFilters}
                      selected={isFilterOpen}
                      onChange={toggleIsFilterOpen}
                    />
                  </Tooltip>
                </Grid>
                <RenderIf condition={canExportOrganizationsList}>
                  <Grid item>
                    <Tooltip title={t('downloadCSVModal.tooltip')}>
                      <ExportButton onClick={handleDownloadCSVModalOpen} />
                    </Tooltip>
                  </Grid>
                </RenderIf>
                <RenderIf condition={userCan(ACLEnum.OrganizationMove) && isUniqkey(partnerType)}>
                  <Grid item my={0.5}>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item alignSelf="center">
                    <Tooltip title={moveTooltipTitle}>
                      <ActionButton
                        width={40}
                        height={40}
                        onClick={handleMoveOrganizationsModalOpen}
                        disabled={isMoveOrganizationsDisabled}
                      >
                        <MoveIcon />
                      </ActionButton>
                    </Tooltip>
                  </Grid>
                  <Grid item my={0.5}>
                    <Divider orientation="vertical" />
                  </Grid>
                </RenderIf>
                {/* TODO: actions here */}
              </Grid>
              <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
                <Grid item>
                  <SearchableTextField
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={t('common.search')}
                  />
                </Grid>
                <Box mr={3} />
                <Grid item>
                  <Button
                    icon={<OrganizationIcon />}
                    onClick={handleCreateOrganizationModalOpen}
                  >
                    {t('organizationsExtendedPage.createOrganizationButton')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Panel>
      </Grid>
      <Grid item xs>
        <Panel>
          {(movedFromPartner?.value || allMovedPartners) ? (
            <OrganizationsExtendedTableWithFilter
              selectedOrganizationsExtended={selectedOrganizationsExtended}
              options={TABLE_OPTIONS}
              {...restTableProps}
            />
          ) : (
            <OrganizationsExtendedTable
              selectedOrganizationsExtended={selectedOrganizationsExtended}
              options={TABLE_OPTIONS}
              {...restTableProps}
            />
          )}
        </Panel>
      </Grid>
      {isCreateOrganizationModalOpen && (
        <CreateOrganizationModal
          isOpen={isCreateOrganizationModalOpen}
          isLoading={isCreateOrganizationLoading}
          onSubmit={handleCreateOrganization}
          onClose={handleCreateOrganizationModalClose}
        />
      )}
      <OrganizationsExtendedListFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
      {isDownloadCSVModalOpen && (
        <DownloadCSVModal
          isOpen={isDownloadCSVModalOpen}
          isLoading={isDownloadCSVLoading}
          onSubmit={handleDownloadCSV}
          onClose={handleDownloadCSVModalClose}
          selectedOrganizations={selectedOrganizationsExtended}
        />
      )}
      {isMoveOrganizationsModalOpen && (
        <MoveOrganizationsModal
          open={isMoveOrganizationsModalOpen}
          onClose={handleMoveOrganizationsModalClose}
          isLoading={isMoveOrganizationsLoading}
          onSubmit={handleMoveOrganizations}
          selectedOrganizationsAsObjects={selectedOrganizationsExtendedAsObjects}
        />
      )}
    </Grid>
  );
};

export default OrganizationsExtendedPage;
