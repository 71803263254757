import { memo, useMemo } from 'react';
import {
  IReactHookFormSelectProps,
  MenuItem,
  ReactHookFormSelect,
  useTranslations,
} from '@uniqkey-frontend/shared-app';
import { PartnerType } from '@uniqkey-backend-partner/api-client';
import {
  getPartnerTypeOptions, isDistributor,
} from '../../../../helpers/partnerType';

interface IPartnerTypeSelectProps extends IReactHookFormSelectProps<any> {
  type: PartnerType;
  label: string;
}

const PartnerTypeSelect = (props: IPartnerTypeSelectProps) => {
  const {
    type,
    name,
    control,
    label,
    fullWidth = true,
    disabled = isDistributor(type),
    ...restProps
  } = props;
  const { t } = useTranslations();

  const optionsElements = useMemo(() => {
    const options = getPartnerTypeOptions(t, type);
    if (!options.length) {
      return null;
    }
    return options.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  }, [type, t]);

  if (!optionsElements) {
    return null;
  }

  return (
    <ReactHookFormSelect
      name={name}
      control={control}
      fullWidth={fullWidth}
      label={label}
      disabled={disabled}
      {...restProps}
    >
      {optionsElements}
    </ReactHookFormSelect>
  );
};

export default memo(PartnerTypeSelect);
