import { useCallback } from 'react';
import { AxiosRequestConfig } from 'axios';
import { GetMoveInfoResponse } from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const usePartnerSettingsAPI = () => {
  const { partnerSettingsAPIClient } = useApiClients();

  const getPartnerMoveInfo = useCallback(
    (partnerId: string, options?: AxiosRequestConfig)
      : Promise<GetMoveInfoResponse> => partnerSettingsAPIClient
      .apiV1PartnerSettingsGetMoveInfoPartnerIdGet(partnerId, options)
      .then(dataExtractor<GetMoveInfoResponse>()),
    [partnerSettingsAPIClient],
  );

  return {
    getPartnerMoveInfo,
  };
};

export default usePartnerSettingsAPI;
