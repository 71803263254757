import { useCallback } from 'react';
import { type AxiosRequestConfig } from 'axios';
import {
  UpdateUserProfileRequest,
  NoContentResult,
} from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const useUserProfileAPI = () => {
  const { userProfileAPIClient } = useApiClients();

  const updateCurrentUser = useCallback(
    (updateUserProfileRequest?: UpdateUserProfileRequest, options?: AxiosRequestConfig)
  : Promise<NoContentResult> => userProfileAPIClient
      .apiV1UserProfilePut(updateUserProfileRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [userProfileAPIClient],
  );

  return {
    updateCurrentUser,
  };
};

export default useUserProfileAPI;
