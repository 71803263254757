import { useCallback } from 'react';
import { type AxiosRequestConfig } from 'axios';
import {
  GetContactPersonByIdResponse,
  UpdateContactPersonRequest,
  NoContentResult,
} from '@uniqkey-backend-partner/api-client';
import { useApiClients } from '../../contexts/APIClientsContext';
import { dataExtractor } from '../../helpers/apiClients';

const useContactPersonAPI = () => {
  const { contactPersonAPIClient } = useApiClients();

  const getContactPersonById = useCallback(
    (contactPersonId: string, options?: AxiosRequestConfig)
      : Promise<GetContactPersonByIdResponse> => contactPersonAPIClient
      .apiV1ContactPersonContactPersonIdGet(contactPersonId, options)
      .then(dataExtractor<GetContactPersonByIdResponse>()),
    [contactPersonAPIClient],
  );

  const updateContactPerson = useCallback(
    (updateContactPersonRequest?: UpdateContactPersonRequest, options?: AxiosRequestConfig)
      : Promise<NoContentResult> => contactPersonAPIClient
      .apiV1ContactPersonPut(updateContactPersonRequest, options)
      .then(dataExtractor<NoContentResult>()),
    [contactPersonAPIClient],
  );

  return {
    getContactPersonById,
    updateContactPerson,
  };
};

export default useContactPersonAPI;
