import { memo } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ReactHookFormTextField,
  useTranslations,
  validateEmail,
  emptyStringValidator,
  buildInvalidFieldTranslation,
} from '@uniqkey-frontend/shared-app';
import { MeResponse } from '@uniqkey-backend-partner/api-client';
import { useForm } from 'react-hook-form';
import { UNIQKEY_FINDER_IGNORE_CLASSNAME_PROP } from '../../../../constants';

interface IFormValue {
  name: MeResponse['name']
  email: MeResponse['email']
}

export interface IEditProfileModalSubmitResult extends IFormValue {}

interface IEditProfileModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: (params: IFormValue) => Promise<void> | void;
  onClose: () => void;
  currentUser: MeResponse;
}

const EditProfileModal = (props: IEditProfileModalProps) => {
  const {
    isOpen, isLoading, onSubmit, onClose, currentUser,
  } = props;

  const { t } = useTranslations();

  const { name, email } = currentUser;

  const {
    handleSubmit, formState: { isDirty, errors }, control,
  } = useForm<IFormValue>({
    defaultValues: { name, email },
    mode: 'all',
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      clickOutsideToClose={!isLoading}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle isLoading={isLoading} onClose={onClose}>
          {t('editProfileModal.title')}
        </DialogTitle>
        <DialogContent>
          <Grid container columnSpacing={2}>
            <Grid item xs={6}>
              <ReactHookFormTextField
                autoFocus
                name="name"
                control={control}
                fullWidth
                label={`${t('editProfileModal.name.label')}*`}
                placeholder={t('editProfileModal.name.placeholder')}
                error={!!errors.name}
                helperText={errors.name?.message}
                rules={{
                  required: t('validation.required'),
                  validate: (value) => (
                    emptyStringValidator(value)
                      ? buildInvalidFieldTranslation('editProfileModal.name.label')
                      : true
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReactHookFormTextField
                name="email"
                control={control}
                fullWidth
                label={`${t('editProfileModal.email.label')}*`}
                placeholder={t('editProfileModal.email.placeholder')}
                error={!!errors.email}
                helperText={errors.email?.message}
                rules={{
                  required: t('validation.required'),
                  validate: {
                    isValid: (value) => validateEmail(value)
                      || buildInvalidFieldTranslation('editProfileModal.email.label'),
                  },
                }}
                inputProps={UNIQKEY_FINDER_IGNORE_CLASSNAME_PROP}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container columnSpacing={2}>
            <Grid item xs />
            <Grid item xs={4} md={2.5}>
              <Button fullWidth isLoading={isLoading} disabled={!isDirty} type="submit">
                {t('common.save')}
              </Button>
            </Grid>
            <Grid item xs={4} md={2.5}>
              <Button fullWidth variant="outlined" disabled={isLoading} onClick={onClose}>
                {t('common.cancel')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default memo(EditProfileModal);
