import {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { upperFirst } from 'lodash';
import {
  useSnackbar,
  useTable,
  useQueryTableFetch,
  useTranslations,
  useTableLocalization,
  useFilterButton,
  useTablePreserverActivePageValidator,
  useTablePreserverDecoder,
  useTablePreserverEncoder,
  usePubSub,
  buildTablePreserverDecoderConfigItem,
  TablePreserverCommonInitialTableValues,
  IUseTableReturn,
  IUseTableResetTableMethodsReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
  IUseFilterButtonReturn,
  TUseTablePreserverDecoderConfigs,
  TablePreserverDecoderConfigItemTypeEnum,
  TSetSearchParams,
} from '@uniqkey-frontend/shared-app';
import {
  OrganizationsExtendedOrderProperty,
  GetExtendedOrganizationsResponseModel,
} from '@uniqkey-backend-partner/api-client';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import PubSubEventEnum from '../../../enums/PubSubEventEnum';
import ReactQueryKeyEnum from '../../../enums/ReactQueryKeyEnum';
import {
  IGetExtendedOrganizationsParams,
  TGetExtendedOrganizationsMethod,
} from '../../usePartnerOrganizationsAPI/interfaces';
import usePartnerOrganizationsAPI from '../../usePartnerOrganizationsAPI';
import {
  IOrganizationsExtendedListFilterSubmitResult,
} from '../../../pages/OrganizationsExtendedPage/components/OrganizationsExtendedListFilter';
import { isCountryValid } from '../../../helpers/countries';

export interface IOrganizationsExtendedTableRow extends GetExtendedOrganizationsResponseModel {}

export const REACT_QUERY_ORGANIZATIONS_EXTENDED_KEY = [ReactQueryKeyEnum.OrganizationsExtended];

export interface IUseOrganizationsExtendedTableParams {
  noDataMessageKey: string;
}

export interface IUseOrganizationsExtendedTableReturn extends
  Omit<IUseTableReturn<IOrganizationsExtendedTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Pick<IUseTableResetTableMethodsReturn, 'resetActivePage' | 'resetSelectedRows'>,
  Omit<IUseQueryTableFetchReturn<TGetExtendedOrganizationsMethod>, 'data' | 'isFetchedAfterMount'>,
  IUseFilterButtonReturn
{
  organizationsExtended: IUseQueryTableFetchReturn<TGetExtendedOrganizationsMethod>['data'];
  selectedOrganizationsExtended: IUseTableReturn<
    IOrganizationsExtendedTableRow
  >['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  searchQuery: IGetExtendedOrganizationsParams['searchQuery'];
  setSearchQuery: Dispatch<SetStateAction<IGetExtendedOrganizationsParams['searchQuery']>>;
  filterValues: IOrganizationsExtendedListFilterSubmitResult;
  setFilterValues: Dispatch<SetStateAction<IOrganizationsExtendedListFilterSubmitResult>>;
}

interface ITablePreservationConfigs extends
  Omit<IGetExtendedOrganizationsParams,
    'page' | 'pageLength' | 'partnerId' | 'orderPropertyName' | 'movedFromPartnerId' |
    'isDescending'
  >,
  Pick<IUseTableReturn<IOrganizationsExtendedTableRow>, 'columnOrderBy' | 'columnOrderDirection'>
{
  activePage: IGetExtendedOrganizationsParams['page'];
  perPage: IGetExtendedOrganizationsParams['pageLength'];
  partner: IOrganizationsExtendedListFilterSubmitResult['partner'];
  movedFromPartner: IOrganizationsExtendedListFilterSubmitResult['movedFromPartner'];
}

const PRESERVATION_CONFIGS: TUseTablePreserverDecoderConfigs<ITablePreservationConfigs> = {
  activePage: true,
  perPage: true,
  searchQuery: true,
  columnOrderBy: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.ENUM,
    { enumToCheck: OrganizationsExtendedOrderProperty },
  ),
  columnOrderDirection: true,
  countryFilter: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.STRING,
    { isValid: isCountryValid },
  ),
  partner: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.OBJECT,
  ),
  movedFromPartner: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.OBJECT,
  ),
  allMovedPartners: buildTablePreserverDecoderConfigItem(
    TablePreserverDecoderConfigItemTypeEnum.BOOLEAN,
  ),
};

const useOrganizationsExtendedTable = (
  params: IUseOrganizationsExtendedTableParams,
): IUseOrganizationsExtendedTableReturn => {
  const {
    noDataMessageKey,
  } = params;

  const queryClient = useQueryClient();
  const { t } = useTranslations();
  const { showError } = useSnackbar();
  const { getExtendedOrganizations } = usePartnerOrganizationsAPI();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOnRequestError = useCallback(() => {
    showError({ text: t('common.somethingWentWrong') });
  }, [showError, t]);

  const {
    activePage: initialActivePage,
    perPage: initialPerPage,
    countryFilter: initialCountryFilter,
    partner: initialPartner,
    movedFromPartner: initialMovedFromPartner,
    allMovedPartners: initialAllMovedPartners,
    searchQuery: initialSearchQuery,
    columnOrderBy: initialColumnOrderBy,
    columnOrderDirection: initialColumnOrderDirection,
  } = useTablePreserverDecoder<ITablePreservationConfigs>(searchParams, PRESERVATION_CONFIGS);

  const [searchQuery, setSearchQuery] = useState<
    IGetExtendedOrganizationsParams['searchQuery']
  >(() => initialSearchQuery);
  const [filterValues, setFilterValues] = useState<
    IOrganizationsExtendedListFilterSubmitResult
  >({
    countryFilter: initialCountryFilter,
    partner: initialPartner,
    movedFromPartner: initialMovedFromPartner,
    allMovedPartners: initialAllMovedPartners,
  });

  const { isFilterActive, numberOfActiveFilters } = useFilterButton(filterValues);

  const {
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedRows: selectedOrganizationsExtended,
    resetTableMethods,
    ...restTableProps
  } = useTable<IOrganizationsExtendedTableRow>({
    initialActivePage,
    initialPerPage,
    initialColumnOrderBy,
    initialColumnOrderDirection,
  });

  const {
    resetTable,
    resetActivePage,
    resetSelectedRows,
  } = resetTableMethods;

  const handleTableReset = useCallback(() => {
    resetTable();
    setSearchQuery(TablePreserverCommonInitialTableValues.searchQuery);
    setFilterValues({
      countryFilter: undefined,
      partner: undefined,
      movedFromPartner: undefined,
      allMovedPartners: undefined,
    });
  }, [resetTable]);
  usePubSub(PubSubEventEnum.RESET_TABLE, handleTableReset);

  const {
    data: organizationsExtended, isLoading, isFetchedAfterMount, total, resetQuery,
  } = useQueryTableFetch({
    queryKey: REACT_QUERY_ORGANIZATIONS_EXTENDED_KEY,
    queryClient,
    request: getExtendedOrganizations,
    params: {
      page: activePage,
      pageLength: perPage,
      countryFilter: filterValues.countryFilter,
      partnerId: filterValues.partner?.value,
      movedFromPartnerId: filterValues.movedFromPartner?.value,
      allMovedPartners: filterValues.allMovedPartners,
      searchQuery,
      orderPropertyName: OrganizationsExtendedOrderProperty[
        upperFirst(columnOrderBy) as keyof typeof OrganizationsExtendedOrderProperty
      ],
      isDescending: columnOrderDirection ? columnOrderDirection === 'desc' : undefined,
    },
    onRequestError: handleOnRequestError,
    autoRefetch: true,
  });

  const { localization } = useTableLocalization({
    searchQuery,
    isFilterActive,
    noDataMessageKey,
    isLoading,
  });

  useTablePreserverActivePageValidator({
    activePage, resetActivePage, perPage, total, isFetchedAfterMount,
  });

  useTablePreserverEncoder<ITablePreservationConfigs>(setSearchParams as TSetSearchParams, {
    activePage,
    perPage,
    searchQuery,
    columnOrderBy,
    columnOrderDirection,
    countryFilter: filterValues.countryFilter,
    partner: {
      value: filterValues.partner,
      parser: JSON.stringify,
    },
    movedFromPartner: {
      value: filterValues.movedFromPartner,
      parser: JSON.stringify,
    },
    allMovedPartners: filterValues.allMovedPartners,
  });

  return {
    organizationsExtended,
    isLoading,
    total,
    resetQuery,
    activePage,
    perPage,
    columnOrderBy,
    columnOrderDirection,
    selectedOrganizationsExtended,
    localization,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps,
  };
};

export default useOrganizationsExtendedTable;
