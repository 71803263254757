import { PartnerType } from '@uniqkey-backend-partner/api-client';
import { TFunction } from '@uniqkey-frontend/shared-app';
import { IPartnerTypeOption } from './interfaces';
import { PARTNER_TYPES_TRANSLATION_KEYS } from '../../constants';

const OPTIONS = {
  [PartnerType.Ambassador]: {
    labelTranslationKey: PARTNER_TYPES_TRANSLATION_KEYS[PartnerType.Ambassador],
    value: PartnerType.Ambassador,
  },
  [PartnerType.Distributor]: {
    labelTranslationKey: PARTNER_TYPES_TRANSLATION_KEYS[PartnerType.Distributor],
    value: PartnerType.Distributor,
  },
  [PartnerType.Sales]: {
    labelTranslationKey: PARTNER_TYPES_TRANSLATION_KEYS[PartnerType.Sales],
    value: PartnerType.Sales,
  },
};

// eslint-disable-next-line import/prefer-default-export
export const PARTNER_TYPE_TO_OPTIONS = {
  [PartnerType.UniqKey]: {
    options: [
      OPTIONS[PartnerType.Ambassador],
      OPTIONS[PartnerType.Distributor],
      OPTIONS[PartnerType.Sales],
    ],
    defaultValue: PartnerType.Ambassador,
  },
  [PartnerType.Ambassador]: {
    options: [
      OPTIONS[PartnerType.Distributor],
      OPTIONS[PartnerType.Sales],
    ],
    defaultValue: PartnerType.Distributor,
  },
  [PartnerType.Distributor]: {
    options: [
      OPTIONS[PartnerType.Sales],
    ],
    defaultValue: PartnerType.Sales,
  },
};

export const getPartnerTypeOptions = (
  t: TFunction,
  type: PartnerType,
): IPartnerTypeOption[] => {
  const { options = [] } = PARTNER_TYPE_TO_OPTIONS[
    type as keyof typeof PARTNER_TYPE_TO_OPTIONS
  ] ?? {};
  return options.map((option) => (
    { label: t(option.labelTranslationKey), value: option.value }
  ));
};

export const isSales = (type?: PartnerType) => type === PartnerType.Sales;
export const isDistributor = (type?: PartnerType) => type === PartnerType.Distributor;
export const isAmbassador = (type?: PartnerType) => type === PartnerType.Ambassador;
export const isUniqkey = (type?: PartnerType) => type === PartnerType.UniqKey;
