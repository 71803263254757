import { useCallback, useMemo, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TypographyWithTooltip,
  useTranslations,
  IMaterialTableOptions,
  IMaterialTableProps,
  IUseTableReturn,
  IUseQueryTableFetchReturn,
  IUseTableLocalizationReturn,
} from '@uniqkey-frontend/shared-app';
import PageRouteEnum from '../../../enums/PageRouteEnum';
import { PARTNER_TYPES_TRANSLATION_KEYS } from '../../../constants';
import { TGetPartnersMethod } from '../../../hooks/usePartnersAPI/interfaces';
import { IPartnersTableRow } from '../../../hooks/tables/usePartnersTable';

interface IPartnersTableWithFilterProps extends
  Omit<IUseTableReturn<IPartnersTableRow>, 'selectedRows' | 'resetTableMethods'>,
  Omit<IUseQueryTableFetchReturn<TGetPartnersMethod>, 'data' | 'resetQuery' | 'isFetchedAfterMount'>
{
  partners: IUseQueryTableFetchReturn<TGetPartnersMethod>['data'];
  selectedPartners: IUseTableReturn<IPartnersTableRow>['selectedRows'];
  localization: IUseTableLocalizationReturn['localization'];
  partnerId?: string;
  partnerId1?: string;
  partnerId2?: string;
}

const TABLE_OPTIONS: IMaterialTableOptions<IPartnersTableRow> = {
  selection: true,
};

const COLUMN_TOOLTIP_PROPS = { cursorPointer: true };

const PartnersTableWithFilter = (props: IPartnersTableWithFilterProps) => {
  const {
    partners,
    isLoading,
    total,
    localization,
    partnerId,
    partnerId1,
    partnerId2,
    activePage,
    setActivePage,
    perPage,
    onPerPageChange,
    columnOrderBy,
    onColumnOrderByChange,
    columnOrderDirection,
    onColumnOrderDirectionChange,
    selectedPartners,
    onRowSelectionChange,
    createColumns,
  } = props;

  const { t } = useTranslations();
  const navigate = useNavigate();

  const columns = useMemo(() => createColumns([
    {
      title: t('partnersPage.vat'),
      field: 'vatNumber',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.vatNumber}
        </TypographyWithTooltip>
      ),
      width: '10%',
    },
    {
      title: t('partnersPage.movedFromPartnerName'),
      field: 'movedFromPartnerName',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.movedFromPartnerName}
        </TypographyWithTooltip>
      ),
      width: '10%',
      sorting: false,
    },
    {
      title: t('partnersPage.partner'),
      field: 'name',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.name}
        </TypographyWithTooltip>
      ),
      width: '10%',
    },
    {
      title: t('partnersPage.region'),
      field: 'region',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.region}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('partnersPage.country'),
      field: 'country',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.country}
        </TypographyWithTooltip>
      ),
      width: '15%',
    },
    {
      title: t('partnersPage.childrenAmount'),
      field: 'childrenAmount',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.childrenAmount}
        </TypographyWithTooltip>
      ),
      width: '10%',
      sorting: false,
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={COLUMN_TOOLTIP_PROPS}
          lineClamp={5}
          title={t('partnersPage.committedLicences.columnTooltip')}
        >
          {t('partnersPage.committedLicences')}
        </TypographyWithTooltip>
      ),
      field: 'committedLicenseCount',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.committedLicenseCount}
        </TypographyWithTooltip>
      ),
      width: '10%',
      sorting: false,
    },
    {
      title: (
        <TypographyWithTooltip
          variant="subtitle1"
          tooltipProps={COLUMN_TOOLTIP_PROPS}
          lineClamp={5}
          title={t('partnersPage.licences.columnTooltip')}
        >
          {t('partnersPage.licences')}
        </TypographyWithTooltip>
      ),
      field: 'licenseCount',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {rowData.licenseCount}
        </TypographyWithTooltip>
      ),
      width: '10%',
      sorting: false,
    },
    {
      title: t('partnersPage.type'),
      field: 'type',
      render: (rowData) => (
        <TypographyWithTooltip variant="body2">
          {t(PARTNER_TYPES_TRANSLATION_KEYS[
            rowData.type as keyof typeof PARTNER_TYPES_TRANSLATION_KEYS
          ])}
        </TypographyWithTooltip>
      ),
      width: '10%',
    },
  ]), [createColumns, t]);

  const handlePartnerRowClick = useCallback<
    NonNullable<IMaterialTableProps<IPartnersTableRow>['onRowClick']>
  >((event, partner) => {
    const { partnerId: clickedPartnerId } = partner!;
    if (partnerId2) {
      navigate(`${PageRouteEnum.PartnersDeep}/${partnerId1}/${partnerId2}/${clickedPartnerId}`);
      return;
    }
    if (partnerId) {
      navigate(`${PageRouteEnum.PartnersDeep}/${partnerId}/${clickedPartnerId}/${null}`);
      return;
    }
    navigate(`${PageRouteEnum.Partners}/${clickedPartnerId}`);
  }, [partnerId, partnerId1, partnerId2, navigate]);

  return (
    <Table
      t={t}
      totalItems={total}
      columns={columns}
      data={partners}
      isLoading={isLoading}
      options={TABLE_OPTIONS}
      activePage={activePage}
      onActivePageChange={setActivePage}
      perPage={perPage}
      onPerPageChange={onPerPageChange}
      columnOrderBy={columnOrderBy}
      onColumnOrderByChange={onColumnOrderByChange}
      columnOrderDirection={columnOrderDirection}
      onColumnOrderDirectionChange={onColumnOrderDirectionChange}
      selectedRows={selectedPartners}
      onSelectionChange={onRowSelectionChange}
      localization={localization}
      onRowClick={handlePartnerRowClick}
      rowSelectByKey="partnerId"
      rowHeight={51}
    />
  );
};

export default memo(PartnersTableWithFilter);
